import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/catalogue',
    name: 'catalogue',
    component: () => import(/* webpackChunkName: "about" */ '../views/catalogue.vue')
  },
  {
    path: '/blurb',
    name: 'blurb',
    component: () => import(/* webpackChunkName: "about" */ '../views/blurb.vue')
  },
  {
    path: '/charm',
    name: 'charm',
    component: () => import(/* webpackChunkName: "about" */ '../views/charm.vue')
  },
  {
    path: '/charm1',
    name: 'charm1',
    component: () => import(/* webpackChunkName: "about" */ '../views/charm1.vue')
  },
  {
    path: '/village',
    name: 'village',
    component: () => import(/* webpackChunkName: "about" */ '../views/village.vue')
  },
  {
    path: '/village1',
    name: 'village1',
    component: () => import(/* webpackChunkName: "about" */ '../views/village1.vue')
  },
  {
    path: '/gangao',
    name: 'gangao',
    component: () => import(/* webpackChunkName: "about" */ '../views/gangao.vue')
  },
  {
    path: '/gangao1',
    name: 'gangao1',
    component: () => import(/* webpackChunkName: "about" */ '../views/gangao1.vue')
  },
  {
    path: '/Overseas',
    name: 'Overseas',
    component: () => import(/* webpackChunkName: "about" */ '../views/Overseas.vue')
  },
  {
    path: '/Overseas1',
    name: 'Overseas1',
    component: () => import(/* webpackChunkName: "about" */ '../views/Overseas1.vue')
  },
  {
    path: '/Industrial',
    name: 'Industrial',
    component: () => import(/* webpackChunkName: "about" */ '../views/Industrial.vue')
  },
  {
    path: '/Industrial1',
    name: 'Industrial1',
    component: () => import(/* webpackChunkName: "about" */ '../views/Industrial1.vue')
  },
  {
    path: '/zhaoshangyinzi',
    name: 'zhaoshangyinzi',
    component: () => import(/* webpackChunkName: "about" */ '../views/zhaoshangyinzi.vue')
  },
  {
    path: '/zhengcecuoshi',
    name: 'zhengcecuoshi',
    component: () => import(/* webpackChunkName: "about" */ '../views/zhengcecuoshi.vue')
  },
  {
    path: '/book',
    name: 'book',
    component: () => import(/* webpackChunkName: "about" */ '../views/book.vue')
  },
  {
    path: '/Calligraphy',
    name: 'Calligraphy',
    component: () => import(/* webpackChunkName: "about" */ '../views/Calligraphy.vue')
  },
  {
    path: '/Calligraphy1',
    name: 'Calligraphy1',
    component: () => import(/* webpackChunkName: "about" */ '../views/Calligraphy1.vue')
  },
  {
    path: '/Calligraphynew',
    name: 'Calligraphynew',
    component: () => import(/* webpackChunkName: "about" */ '../views/Calligraphynew.vue')
  },
  {
    path: '/activity-site',
    name: 'activity-site',
    component: () => import(/* webpackChunkName: "about" */ '../views/activity-site.vue')
  },
  1==1?{ path: '*',redirect: '/' }:'',
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
// router.beforeEach(to=>{
  
// })
router.afterEach(to => {
  
  // console.log(123);
  console.log(localStorage.getItem('type'));
  let version = sessionStorage.getItem('version');
  // let str = sessionStorage.getItem('str');
  // if(!str){
  //   str=Math.random(0,10)
  //   sessionStorage.setItem('str',str)
  // }
  if(version != '1.056'){
    sessionStorage.setItem('version','1.056') 
    location.reload()
  }
  window.scrollTo(0, 0)
})
router.beforeEach(async (to, from, next) => {
  let query=to.query
  let type = localStorage.getItem('type');
  if(!to.query.type){
    if(type){
      query.type = localStorage.getItem('type');
    }else{
      query.type = 'Ultimate';
    }
    next({name:to.name,query:query})
  }else{
    next()
  }
  
})
export default router
